import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { logEvent, getAnalytics } from "firebase/analytics";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplier, fetchedSupplier } from "../../redux/actions/supplier";
import { STATE_STATUS } from "../../redux/constants/index";
import { createSupplierProfile } from "../../services/supplier";

const SupplierOnboarding = (props) => {
  const supplierData = useSelector((state) => state?.supplier.get("data"));
  const supplierStatus =
    useSelector((state) => state?.supplier.get("status")) ||
    STATE_STATUS.UNFETCHED;

  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const [isSetfromUser, setIsSetFromUser] = useState(false);
  const [initLoader, setInitLoader] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showAllfields, setShowAllfields] = useState(false);
  const [isAcceptance, setIsAcceptance] = useState(false);

  const {
    companyName,
    companyAddress,
    gstin,
    // pan,
    // cancelledCheque,
    installationPhotos,
    // digitalSignature,
    catalogue,
    referenceList,
    kwInstalled,
    top3Projects,
    customerFeedback,
    // companyPhoto,
    // personPhoto,
    onChangeSupplierDetails,
    authData,
  } = props;

  useEffect(() => {
    if (Object.keys(authData)?.length) {
      if (authData?.user_type == 3) {
        navigate("/onboarding");
      } else {
        dispatch(fetchSupplier());
      }
    }
  }, [authData]);

  useEffect(() => {
    if (supplierStatus == STATE_STATUS.FETCHED) {
      if (!supplierData.size) {
        setInitLoader(false);
      } else {
        setInitLoader(false);
        navigate("/supplier-dashboard");
      }
    }
  }, [supplierStatus]);

  const imageUploader = async (file, title) => {
    try {
      const imageRef = storageRef(
        storage,
        `resixty/suppliers/${authData?.id}/documents/${title}`
      );

      let snapshot = await uploadBytes(imageRef, file);
      let url = await getDownloadURL(snapshot.ref);
      return url;
    } catch (e) {
      console.log(e, "error uploading");
      return "";
    }
  };

  const onSubmit = async () => {
    try {
      setLoader(true);
      let iterator = [
        // { key: "cancelled_cheque", val: cancelledCheque, type: "single" },
        {
          key: "installation_photos",
          val: installationPhotos,
          type: "multiple",
        },
        // { key: "digital_signatures", val: digitalSignature, type: "single" },
        { key: "catalogue", val: catalogue, type: "single" },
        { key: "reference_list", val: referenceList, type: "multiple" },
        { key: "customer_feedback", val: customerFeedback, type: "single" },
        // { key: "company_photo", val: companyPhoto, type: "single" },
        // { key: "person_photo", val: personPhoto, type: "single" },
      ];
      let obj = {
        company_name: companyName,
        company_address: companyAddress,
        gstin: gstin,
        // pan: pan,
        // cancelled_cheque: null,
        installation_photos: null,
        // digital_signatures: null,
        catalogue: null,
        reference_list: null,
        kw_installed: kwInstalled,
        top_3_projects: top3Projects,
        customer_feedback: customerFeedback,
        // company_photo: companyPhoto,
        // person_photo: personPhoto,
      };
      await Promise.all(
        await iterator.map(async (_) => {
          if (_?.val?.length) {
            if (_.type == "single") {
              let image = await imageUploader(_?.val?.[0], _?.key);
              obj[`${_.key}`] = image;
            } else {
              let len = _?.val?.length;
              let i = 0;
              while (i < len) {
                let current = _?.val?.[i];
                let image = await imageUploader(current, `${_.key}-${i}`);
                if (obj?.[`${_.key}`]) {
                  obj[`${_.key}`].push(image);
                } else {
                  obj[`${_.key}`] = [];
                  obj[`${_.key}`].push(image);
                }
                i++;
              }
            }
          } else {
            if (_.type == "single") {
              return "";
            } else {
              return [];
            }
          }
        })
      );
      const { data } = await createSupplierProfile(obj);
      if (data?.success) {
        setInitLoader(true);
        dispatch(fetchedSupplier(data?.data));
        navigate("/supplier-dashboard");
        setLoader(false);
        alert(
          "Thank you for onborading with Resixty. Our team will get back to you in 24-48 hours."
        );
      } else {
        alert(data?.message);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
    }
  };

  return (
    <div className="container">
      <div
        className={
          "cardForm df fdr fww" + ` ${showAllfields ? "" : "width-50"}`
        }
      >
        {/* <div className="w-50"> */}
        {initLoader ? (
          <i
            className="fa fa-spinner fa-spin mt-10 asc df"
            style={{ fontSize: "30px", color: "#fff" }}
          ></i>
        ) : (
          <>
            <h4 className="colorGreen">Partner Onboarding</h4>
            <div className={"onboardingContainer jcsb mt-10"}>
              {/* <span className="sectionText">Complete Your Profile</span> */}
              <div className={"w-30"}>
                <label className="mt-10" htmlFor="companyName">
                  Company Name
                </label>
                <input
                  value={companyName}
                  onChange={onChangeSupplierDetails}
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="X Y Z Enterprise"
                />
                <label className="mt-10" htmlFor="companyAddress">
                  Company Address
                </label>
                <input
                  value={companyAddress}
                  onChange={onChangeSupplierDetails}
                  type="text"
                  id="companyAddress"
                  name="companyAddress"
                  placeholder="A1 Plot, global area, India"
                />
                <label className="mt-10" htmlFor="gstin">
                  GST
                </label>
                <input
                  value={gstin}
                  onChange={onChangeSupplierDetails}
                  type="text"
                  id="gstin"
                  name="gstin"
                  placeholder="09AAA******1ZZ"
                />
                {!showAllfields ? (
                  <>
                    <div className="df fdr alic mt-10">
                      <div
                        onClick={() => setIsAcceptance(!isAcceptance)}
                        className="checkbox cp"
                      >
                        {isAcceptance ? (
                          <span className="colorGreen">&#10004;</span>
                        ) : null}
                      </div>
                      <p className="colorGreyText ml-5 taj">
                        I accept the{" "}
                        <a
                          target="_blank"
                          href="https://www.resixty.com/termsofuse.html"
                          className="colorGreen tdul"
                        >
                          Terms of Use
                        </a>{" "}
                        ,{" "}
                        <a
                          target="_blank"
                          href="https://www.resixty.com/privacy.html"
                          className="colorGreen tdul"
                        >
                          Privacy Policy
                        </a>{" "}
                        ,{" "}
                        <a
                          target="_blank"
                          href="https://www.resixty.com/refundpolicy.html"
                          className="colorGreen tdul"
                        >
                          Refund Policy
                        </a>
                        {/* This is a one time refundable deposit that you have to pay in
                order to intiate the bidding process. The deposit is refundable
                for more details you can go through our{" "}
                <a className="colorGreen tdul">Refund Policy</a>. The bidding
                process will be initiated as soon as you make a successful
                payment. After the payment goes through our Installers will
                place their bid and Resixty will help in ensuring that you get
                the best bid for your project. */}
                      </p>
                    </div>
                    <div className="df w-100 mt-10 jcc alic fdc">
                      <button
                        disabled={
                          !companyName ||
                          !companyAddress ||
                          !gstin ||
                          !isAcceptance
                          // || !pan
                        }
                        onClick={onSubmit}
                        className="customCta  mt-10 w-100"
                      >
                        <span className="mediumFont colorDark">Continue</span>
                        {loader ? (
                          <i
                            className="fa fa-spinner fa-spin ml-10"
                            style={{ fontSize: "16px" }}
                          ></i>
                        ) : null}
                      </button>
                    </div>
                  </>
                ) : null}
                {/* <label className="mt-10" htmlFor="pan">
                PAN
              </label>
              <input
                value={pan}
                onChange={onChangeSupplierDetails}
                type="text"
                id="pan"
                name="pan"
                placeholder="GBXXXXXXXB"
              /> */}
                {/* <label className="mt-10" htmlFor="cancelledCheque">
                Cancelled Cheque
              </label>
              <input
                onChange={onChangeSupplierDetails}
                type="file"
                // accept="*/
                /*"
                id="cancelledCheque"
                name="cancelledCheque"
              /> */}
                {showAllfields ? (
                  <>
                    <label className="mt-10" htmlFor="installationPhotos">
                      Installation Photos
                    </label>
                    <input
                      multiple
                      onChange={onChangeSupplierDetails}
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      id="installationPhotos"
                      name="installationPhotos"
                    />
                    <label className="mt-10" htmlFor="catalogue">
                      Catalogue
                    </label>
                    <input
                      onChange={onChangeSupplierDetails}
                      type="file"
                      accept=".pdf, .csv, .xls, xlsx"
                      id="catalogue"
                      name="catalogue"
                    />
                  </>
                ) : (
                  false
                )}
                {/* <label className="mt-10" htmlFor="industryType">
                Industry Type
              </label>
              <select
                onChange={onChangeSupplierDetails}
                name="industryType"
                id="industryType"
                defaultValue={industryType}
              >
                <option value="">Select Industy Type</option>
                <option value="Commercial">Commercial</option>
                <option value="Educational Institute">
                  Educational Institute
                </option>
                <option value="Hospital">Hospital</option>
                <option value="Industrial">Industrial</option>
              </select> */}

                {/* <label className="mt-10" htmlFor="digitalSignature">
                Digital Signature
              </label>
              <input
                onChange={onChangeSupplierDetails}
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                id="digitalSignature"
                name="digitalSignature"
              /> */}
              </div>
              {showAllfields ? (
                <div className="w-30">
                  <label className="mt-10" htmlFor="referenceList">
                    Reference List
                  </label>
                  <input
                    multiple
                    onChange={onChangeSupplierDetails}
                    type="file"
                    accept=".pdf, .csv, .xls, xlsx"
                    id="referenceList"
                    name="referenceList"
                  />
                  <label className="mt-10" htmlFor="kwInstalled">
                    kW installed in C&I
                  </label>
                  <input
                    value={kwInstalled}
                    onChange={onChangeSupplierDetails}
                    type="text"
                    id="kwInstalled"
                    name="kwInstalled"
                  />
                  <label className="mt-10" htmlFor="top3Projects">
                    Top 3 Projects
                  </label>
                  <input
                    value={top3Projects}
                    onChange={onChangeSupplierDetails}
                    type="text"
                    id="top3Projects"
                    name="top3Projects"
                  />
                  <label className="mt-10" htmlFor="customerFeedback">
                    Customer Feedback
                  </label>
                  <input
                    // value={customerFeedback}
                    onChange={onChangeSupplierDetails}
                    type="file"
                    id="customerFeedback"
                    name="customerFeedback"
                  />
                  {/* <label className="mt-10" htmlFor="companyPhoto">
                Company Photo
              </label>
              <input
                onChange={onChangeSupplierDetails}
                type="file"
                id="companyPhoto"
                name="companyPhoto"
              />
              <label className="mt-10" htmlFor="personPhoto">
                Person Photo
              </label>
              <input
                onChange={onChangeSupplierDetails}
                type="file"
                id="personPhoto"
                name="personPhoto"
              /> */}
                  <div className="df w-100 mt-10 jcc alic fdc">
                    <button
                      disabled={
                        !companyName ||
                        !companyAddress ||
                        !gstin ||
                        !isAcceptance
                        // || !pan
                      }
                      onClick={onSubmit}
                      className="customCta  mt-10 w-100"
                    >
                      <span className="mediumFont colorDark">Continue</span>
                      {loader ? (
                        <i
                          className="fa fa-spinner fa-spin ml-10"
                          style={{ fontSize: "16px" }}
                        ></i>
                      ) : null}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default SupplierOnboarding;
