import { call, fork, put, takeLatest } from "redux-saga/effects";
import { SUPPLIER_ACTIONS } from "../constants/supplier";
import { getSupplier, getUserQuoteRequest } from "../../services/supplier";
import {
  failedFetchSupplier,
  fetchedSupplier,
  failedFetchQuoteRequests,
  fetchedQuoteRequests,
} from "../actions/supplier";

function* fetchSupplierData({ payload }) {
  try {
    const { data } = yield call(getSupplier);
    if (data.success) {
      yield put(fetchedSupplier(data?.data));
    } else {
      yield put(failedFetchSupplier(data?.message));
    }
  } catch (e) {
    yield put(failedFetchSupplier("error"));
  }
}

function* fetchQuoteRequests({ payload: { bidStatus } }) {
  try {
    const { data } = yield call(getUserQuoteRequest, bidStatus);
    if (data.success) {
      yield put(fetchedQuoteRequests(bidStatus, data?.data));
    } else {
      yield put(failedFetchQuoteRequests(data?.message));
    }
  } catch (e) {
    yield put(failedFetchQuoteRequests("error"));
  }
}

export default fork(function* () {
  yield takeLatest(SUPPLIER_ACTIONS.FETCH_SUPPLIER, fetchSupplierData);
  yield takeLatest(SUPPLIER_ACTIONS.FETCH_QUOTE_REQUESTS, fetchQuoteRequests);
});
