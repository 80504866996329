import { Map, List } from "immutable";
import { STATE_STATUS } from "../constants";
import { SUPPLIER_ACTIONS } from "../constants/supplier";

const initialState = new Map({
  status: STATE_STATUS.UNFETCHED,
  data: new Map({}),
  error: "",
  quoteRequests: new Map({}),
});

const supplierReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIER_ACTIONS.FETCH_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FETCHING)
        .set("error", "")
        .set("data", new Map({}));
    case SUPPLIER_ACTIONS.FETCHED_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FETCHED)
        .set("error", "")
        .set("data", new Map(payload.data));
    case SUPPLIER_ACTIONS.FAILED_FETCH_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FAILED_FETCH)
        .set("data", new Map({}))
        .set("error", payload.error);

    case SUPPLIER_ACTIONS.FETCH_QUOTE_REQUESTS:
      return state
        .setIn(
          ["quoteRequests", payload?.bidStatus, "status"],
          STATE_STATUS.FETCHING
        )
        .setIn(["quoteRequests", payload?.bidStatus, "error"], "")
        .setIn(["quoteRequests", payload?.bidStatus, "data"], new Map({}));
    case SUPPLIER_ACTIONS.FETCHED_QUOTE_REQUESTS:
      return state
        .setIn(
          ["quoteRequests", payload?.bidStatus, "status"],
          STATE_STATUS.FETCHED
        )
        .setIn(["quoteRequests", payload?.bidStatus, "error"], "")
        .setIn(
          ["quoteRequests", payload?.bidStatus, "data"],
          new List(payload.data)
        );
    case SUPPLIER_ACTIONS.FAILED_FETCH_QUOTE_REQUESTS:
      return state
        .setIn(
          ["quoteRequests", payload?.bidStatus, "status"],
          STATE_STATUS.FAILED_FETCH
        )
        .setIn(["quoteRequests", payload?.bidStatus, "data"], new Map({}))
        .setIn(["quoteRequests", payload?.bidStatus, "error"], payload.error);

    default:
      return state;
  }
};

export default supplierReducer;
