import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATE_STATUS } from "../../redux/constants";
import { fetchSupplier } from "../../redux/actions/supplier";
import { Map, OrderedMap } from "immutable";
import "./index.css";

const SupplieProfile = (props) => {
  const supplierData = useSelector((state) => state?.supplier.get("data"));
  const supplierStatus = useSelector((state) => state?.supplier.get("status"));
  const dispatch = useDispatch();

  useEffect(() => {
    getUpdatedData();
  }, []);

  const getUpdatedData = async () => {
    if ([STATE_STATUS.UNFETCHED].includes(supplierStatus)) {
      dispatch(fetchSupplier());
    }
  };

  const SUPPLIER_KEYS = {
    company_name: "Company Name",
    company_address: "Company Address",
    gstin: "GSTIN",
    installation_photos: "Installation Photos",
    catalogue: "Catalogue",
    reference_list: "Reference List",
    kw_installed: "KW installed",
    top_3_projects: "Top 3 Projects",
    customer_feedback: "Customer Feedback",
  };

  const SUPPLIER_DATA = new OrderedMap({
    company_name: supplierData.get("company_name"),
    company_address: supplierData.get("company_address"),
    gstin: supplierData.get("gstin"),
    installation_photos: supplierData.get("installation_photos"),
    catalogue: supplierData.get("catalogue"),
    reference_list: supplierData.get("reference_list"),
    kw_installed: supplierData.get("kw_installed"),
    top_3_projects: supplierData.get("top_3_projects"),
    customer_feedback: supplierData.get("customer_feedback"),
  });

  const isLink = (url) => {
    if (url.includes("https")) {
      return <iframe width={"100rem"} height={"100rem"} src={url} />;
    } else {
      return <h6> {url}</h6>;
    }
  };

  const renderItems = (item) => {
    if (typeof item == "object") {
      return item.map((_) => {
        return <div>{isLink(_)}</div>;
      });
    } else {
      return isLink(item);
    }
  };

  if (
    [STATE_STATUS.FETCHING, STATE_STATUS.UNFETCHED].includes(supplierStatus)
  ) {
    return (
      <div className="df alic jcc mt-20">
        <i
          className="fa fa-spinner fa-spin colorGreen"
          style={{ fontSize: "24px" }}
        ></i>
      </div>
    );
  }

  return (
    <div className="profileConatiner">
      {SUPPLIER_DATA.map((item, key) =>
        item || item?.length ? (
          <div className="profileItem" key={key}>
            <h6>
              <span className="colorGreyText">{SUPPLIER_KEYS[key]} :</span>
              {"  "}
            </h6>
            {renderItems(item)}
          </div>
        ) : null
      ).toList()}
    </div>
  );
};

export default SupplieProfile;
