import axios from "axios";
import { CONFIG } from "../generic/config";

export const geoEncodeAddress = async (address) =>
  axios.get(`${CONFIG.GOOGLE_BASE_URL}${CONFIG.GEO_ENCODING}`, {
    params: {
      address: address,
      key: CONFIG.MAPS_KEY,
    },
  });

export const fetchSolarData = async (params) =>
  axios.get(`${CONFIG.PVWATTS_BASE_URL}`, {
    params: {
      ...params,
      api_key: CONFIG.PVWATTS_API_KEY,
    },
  });

export const reverseGeoEncoding = async (latlong, extraParams = {}) =>
  axios.get(`${CONFIG.GOOGLE_BASE_URL}${CONFIG.GEO_ENCODING}`, {
    params: {
      latlng: latlong,
      key: CONFIG.MAPS_KEY,
      ...extraParams,
    },
  });
