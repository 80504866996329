import React, { useEffect, useState, useRef } from "react";
import { geoEncodeAddress, reverseGeoEncoding } from "../../services/maps";
import "./index.css";
import { CONFIG } from "../../generic/config";
import { useNavigate, useLocation } from "react-router-dom";
import { logEvent, getAnalytics } from "firebase/analytics";
import { getUserFinancialData, saveUserDetails } from "../../services/users";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../../redux/actions/user";
import { fetchSupplier } from "../../redux/actions/supplier";
import { STATE_STATUS } from "../../redux/constants";
import SelectionModal from "../../components/Modal";
import { INDUSTRY_TYPES } from "../../constants/index";

const Onboarding = (props) => {
  const formRef = useRef();
  const userDetailsStatus = useSelector((state) =>
    state?.user.getIn(["userDetails", "status"])
  );
  const userDetailsData = useSelector((state) =>
    state?.user.getIn(["userDetails", "data"])
  );
  const dispatch = useDispatch();
  const analytics = getAnalytics();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state;
  const [showModal, setShowModal] = useState(false);
  const [isFromDetect, setIsFromDetect] = useState(false);
  const [isSetfromUser, setIsSetFromUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectonCompleted, setSelectonCompleted] = useState(false);
  const [initLoader, setInitLoader] = useState(true);
  const {
    industryType,
    name,
    gstin,
    roofTopType,
    customerAddress,
    tilt,
    bill,
    load,
    workingDaysInWeek,
    fromTime,
    toTime,
    averageMonthlyBill,
    connectedLoad,
    perUnitCost,
    onChangeUserDetails,
    area,
    setArea,
    latLong,
    setLatLong,
    address,
    authData,
    setAddress,
  } = props;

  useEffect(() => {
    if (selectonCompleted) {
      formRef?.current?.scrollIntoView();
    }
  }, [selectonCompleted]);

  useEffect(() => {
    if (Object.keys(authData)?.length) {
      logEvent(analytics, "screen_view", {
        name: "MapEntry",
      });
      if (authData?.user_type != 3) {
        navigate("/supplier-onboarding", {
          state: {
            authData,
          },
        });
      } else {
        dispatch(fetchUserDetails());
      }
    }
  }, [authData]);

  useEffect(() => {
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      if (userDetailsData?.size && !locationState?.id) {
        navigate("/output", {
          state: {},
        });
      } else {
        setShowModal(true);
        setInitLoader(false);
      }
    }
  }, [userDetailsStatus]);

  useEffect(() => {
    if (!initLoader) {
      initMap();
    }
  }, [initLoader]);

  const onStartDetectLoaction = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccess);
    }
  };

  const geoSuccess = async (position) => {
    try {
      setIsSetFromUser(true);
      setLatLong({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const { data } = await reverseGeoEncoding(
        `${position.coords.latitude},${position.coords.longitude}`
      );
      if (data?.status == "OK") {
        setIsFromDetect(true);
        setAddress(data?.results?.[0]?.formatted_address);
        onChangeUserDetails({
          target: {
            name: "customerAddress",
            value: data?.results?.[0]?.formatted_address,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (address?.length >= 14 && !isFromDetect) {
      getAddressLatLong();
    }
  }, [address]);

  useEffect(() => {
    initMap();
  }, [latLong]);

  const getAddressLatLong = async () => {
    const { data } = await geoEncodeAddress(address);
    if (data?.results?.length) {
      setLatLong({
        lat: data?.results?.[0]?.geometry?.location?.lat,
        lng: data?.results?.[0]?.geometry?.location?.lng,
      });
    }
  };

  const calculateAzimuth = (lat1, lon1, lat2, lon2) => {
    // Convert the latitudes and longitudes to radians
    let φ1 = (lat1 * Math.PI) / 180;
    let φ2 = (lat2 * Math.PI) / 180;
    let Δλ = ((lon2 - lon1) * Math.PI) / 180;

    // Calculate the y and x components of the azimuth
    let y = Math.sin(Δλ) * Math.cos(φ2);
    let x =
      Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);

    // Calculate the azimuth in degrees
    let θ = (Math.atan2(y, x) * 180) / Math.PI;

    // Return the azimuth
    return θ;
  };

  const initMap = () => {
    if (document.getElementById("googleMap")) {
      const map = new window.google.maps.Map(
        document.getElementById("googleMap"),
        {
          center: latLong,
          zoom: isSetfromUser ? 20 : address.length >= 14 ? 20 : 8,
          mapTypeId: window.google.maps.MapTypeId.HYBRID,
          labels: true,
          styles: [
            {
              featureType: "all",
              elementType: "labels",
              stylers: [{ visibility: "on" }],
            },
            { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
            {
              elementType: "labels.text.stroke",
              stylers: [{ color: "#242f3e" }],
            },
            {
              elementType: "labels.text.fill",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#263c3f" }],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [{ color: "#6b9a76" }],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#38414e" }],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#212a37" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9ca5b3" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#1f2835" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#f3d19c" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#2f3948" }],
            },
            {
              featureType: "transit.station",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#17263c" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#515c6d" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#17263c" }],
            },
          ],
        }
      );
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
        },
        circleOptions: {
          fillColor: "dodgerblue",
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setOptions({
        polygonOptions: {
          fillColor: "blue",
          strokeColor: "blue",
          editable: true,
          // draggable: true,
        },
      });

      const response = new window.google.maps.event.addListener(
        drawingManager,
        "polygoncomplete",
        function (event) {
          const coords = event
            .getPath()
            .getArray()
            .map((coord) => {
              return {
                lat: coord.lat(),
                lng: coord.lng(),
              };
            });
          setSelectonCompleted(true);
          let calculatedArea =
            window.google.maps.geometry.spherical.computeArea(event.getPath());
          logEvent(analytics, "input", {
            input_name: "polygon",
            screen_name: "MapEntry",
            latlng: JSON.stringify(coords),
            area: calculatedArea,
          });
          setArea({
            area: calculatedArea,
            latLng: [...coords],
            azimuth: Math.abs(
              calculateAzimuth(
                coords[0].lat,
                coords[0].lng,
                coords[1].lat,
                coords[1].lng
              )
            ),
            module_type: 1,
            array_type: 0,
            system_capacity:
              Math.floor(calculatedArea / CONFIG.solarPanelArea) *
              CONFIG.solarPanelPower,
            panels:
              Math.floor(calculatedArea / CONFIG.solarPanelArea) * CONFIG.gcr,
            losses: CONFIG.losses,
            albedo: CONFIG.albedo,
            soiling: CONFIG.soiling,
            inv_eff: CONFIG.inv_eff,
            gcr: CONFIG.gcr,
          });
        }
      );
      drawingManager.setMap(map);
    }
  };

  const onNext = async () => {
    try {
      setLoading(true);
      const addData = await reverseGeoEncoding(
        `${area?.latLng?.[0]?.lat},${area?.latLng?.[0]?.lng}`,
        {
          result_type: "locality|political",
        }
      );

      let obj = {
        id: locationState?.id,
        system_capacity:
          Number(area?.system_capacity) > Number(connectedLoad)
            ? Number(connectedLoad)
            : Number(area?.system_capacity),
        name_on_electricity_bill: name,
        user_address:
          (addData?.data?.results?.[0]?.address_components?.[0]?.long_name ||
            "") +
          "|" +
          (addData?.data?.results?.[0]?.address_components?.[1]?.long_name ||
            "") +
          "|" +
          customerAddress,
        roof_type:
          roofTopType == "RccFlat"
            ? 1
            : roofTopType == "SheetMetalSlope"
            ? 2
            : 1,
        discom: "",
        industry_type: industryType,
        tilt: roofTopType == "SheetMetalSlope" ? Number(tilt) : 15,
        gstin,
        working_days_in_week: Number(workingDaysInWeek),
        working_days_in_year: Number(Number(workingDaysInWeek * 52) - 52 - 10),
        from_time: Number(fromTime?.split(":")?.[0]),
        to_time: Number(toTime?.split(":")?.[0]),
        working_hours_in_day:
          Number(toTime?.split(":")?.[0]) - Number(fromTime?.split(":")?.[0]),
        avg_monthly_bill: Number(averageMonthlyBill),
        input_address: customerAddress,
        pincode: "",
        roof_area: area?.area,
        latlng: JSON.stringify(area?.latLng || {}),
        azimuth: Number(area?.azimuth),
        package_type: Number(area?.module_type),
        lat: latLong?.lat,
        lon: latLong?.lng,
        connected_load: Number(connectedLoad),
        sanctioned_load: Number(connectedLoad),
        per_unit_cost: Number(perUnitCost),
      };
      const { data } = await saveUserDetails(obj);
      if (data?.success) {
        // let generatedData = {
        //   startTime: obj?.from_time,
        //   endTime: obj?.to_time,
        //   sanctionedLoad: obj?.sanctioned_load,
        //   connectedLoad: obj?.connected_load,
        //   workingOffInYear: 52,
        //   holidaysInYear: 10,
        //   loanAvailed: true,
        //   tenure: 3,
        //   perUnitCost: Number(perUnitCost),
        //   hourlyAC: data?.data?.ac,
        // };
        // const financials = await getUserFinancialData(generatedData);
        // if (financials?.data?.success) {
        setLoading(false);
        navigate("/output", {
          state: {
            // data: financials?.data,
          },
        });
        // } else {
        //   setLoading(false);
        // }
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e, "error");
    }
    // logEvent(analytics, "click", {
    //   cta: "Continue",
    //   screen_name: "MapEntry",
    //   address,
    //   latlng: JSON.stringify(area?.latlng),
    //   area: area?.area,
    // });
    // navigate("/details", {
    //   state: {
    //     area,
    //     address,
    //   },
    // });
  };

  const renderMap = (withoutInput) => (
    <div className="df w-100 fdc">
      {withoutInput ? null : (
        <div className="searchContainer">
          <div className="df tooltip fdc w-70">
            <input
              className="inputSearch"
              onBlur={() => {
                logEvent(analytics, "input", {
                  input_name: "address",
                  screen_name: "MapEntry",
                  text: address,
                });
              }}
              onChange={(e) => {
                setIsFromDetect(false);
                setAddress(e.target.value);
              }}
              type="text"
              id="address"
              value={address}
              name="address"
              placeholder="A21 Plot 5, Indore, Madhya Pradesh"
            />
            <span className="tooltiptext">
              Type youe address here for roof selection
            </span>
          </div>
          <div className="tooltip detectConatimer">
            <button
              onClick={onStartDetectLoaction}
              className="secondaryCtaSmall detectLocation"
            >
              <span className="mediumFont colorGreen">Detect Location</span>
              <i
                className="colorGreen fas fa-map-marker-alt asc"
                style={{ fontSize: "18px" }}
              ></i>
            </button>
            <span className="tooltiptext">
              Detect your real time location to map the roof
            </span>
          </div>
        </div>
      )}
      <div className="wrapper df fdc w-100 mh-75vh">
        <div id="googleMap" className="goolgeMap w-100 df mh-75vh fdc"></div>
        {selectonCompleted ? (
          <div ref={formRef} className="overMap">
            <div className="instructions w-25 rightDiv">
              <i
                onClick={() => {
                  initMap();
                  setSelectonCompleted(false);
                }}
                className="colorGrey cp fas fa-close alsfe ml-10"
                style={{ fontSize: "18px" }}
              ></i>
              <div className="df fdc w-100">
                <p className="colorYellow tac boldFont">Hi User,</p>
              </div>
              <div className="onboardingContainer">
                <div>
                  <h6 className="colorGreen tac">
                    Consumer / Business Details
                  </h6>
                  <label className="mt-10" htmlFor="name">
                    Industry type
                  </label>
                  <select
                    onChange={onChangeUserDetails}
                    name="industryType"
                    id="industryType"
                    defaultValue={industryType}
                  >
                    <option value="">Select Industry Type</option>
                    <option value={INDUSTRY_TYPES?.COMMERCIAL}>
                      Commercial
                    </option>
                    <option value={INDUSTRY_TYPES?.EDUCATIONAL_INSTITUTE}>
                      Educational Institute
                    </option>
                    <option value={INDUSTRY_TYPES?.HOSPITAL}>Hospital</option>
                    <option value={INDUSTRY_TYPES?.INDUSTRIAL}>
                      Industrial
                    </option>
                    <option value={INDUSTRY_TYPES?.OTHERS}>Other</option>
                  </select>
                  <label className="mt-10" htmlFor="name">
                    Name on electricity bill
                  </label>
                  <input
                    className="w-90"
                    value={name}
                    onChange={onChangeUserDetails}
                    type="text"
                    id="name"
                    name="name"
                    placeholder="John Doe"
                  />
                  <label className="mt-10" htmlFor="customerAddress">
                    Address
                  </label>
                  <input
                    className="w-90"
                    value={customerAddress}
                    onChange={onChangeUserDetails}
                    type="text"
                    id="customerAddress"
                    name="customerAddress"
                    placeholder="Flat No. X, Y Street, M State 000000"
                  />
                  <label className="mt-10" htmlFor="gstin">
                    GSTIN
                  </label>
                  <input
                    className="w-90"
                    value={gstin}
                    onChange={onChangeUserDetails}
                    type="text"
                    id="gstin"
                    name="gstin"
                    placeholder="09AAA******1ZZ"
                  />
                  <label className="mt-10" htmlFor="workingDaysInWeek">
                    Working days in a week
                  </label>
                  <input
                    className="w-90"
                    value={workingDaysInWeek}
                    onChange={onChangeUserDetails}
                    type="number"
                    id="workingDaysInWeek"
                    name="workingDaysInWeek"
                    placeholder="5"
                  />
                  <label className="mt-10 mediumFont">Operation hours</label>
                  <label className="mt-10" htmlFor="fromTime">
                    From time
                  </label>
                  <input
                    className="w-90"
                    value={fromTime}
                    onChange={onChangeUserDetails}
                    type="time"
                    id="fromTime"
                    name="fromTime"
                  />
                  <label className="mt-10" htmlFor="toTime">
                    To time
                  </label>
                  <input
                    className="w-90"
                    value={toTime}
                    onChange={onChangeUserDetails}
                    type="time"
                    id="toTime"
                    name="toTime"
                  />
                  <label className="mt-10" htmlFor="averageMonthlyBill">
                    Average monthly electricity bill (in ₹)
                  </label>
                  <input
                    className="w-90"
                    value={averageMonthlyBill}
                    onChange={onChangeUserDetails}
                    type="number"
                    id="averageMonthlyBill"
                    name="averageMonthlyBill"
                    placeholder="5000"
                  />
                  <label className="mt-10" htmlFor="perUnitCost">
                    Unit Rate (₹/unit)
                  </label>
                  <input
                    className="w-90"
                    value={perUnitCost}
                    onChange={onChangeUserDetails}
                    type="number"
                    id="perUnitCost"
                    name="perUnitCost"
                    placeholder="7.10"
                  />
                  <label className="mt-10" htmlFor="connectedLoad">
                    Connected Load (in kW)
                  </label>
                  <input
                    className="w-90"
                    value={connectedLoad}
                    onChange={onChangeUserDetails}
                    type="number"
                    id="connectedLoad"
                    name="connectedLoad"
                    placeholder="100"
                  />
                  <h6 className="colorGreen mt-20">Rooftop Details</h6>
                  <label className="mt-10" htmlFor="roofTopType">
                    Rooftop type
                  </label>
                  <select
                    className="w-100"
                    onChange={(e) =>
                      onChangeUserDetails({
                        target: { name: "roofTopType", value: e.target.value },
                      })
                    }
                    name="roofTopType"
                    id="cars"
                    defaultValue={roofTopType}
                  >
                    <option value="RccFlat">RCC Flat Roof</option>
                    <option value="SheetMetalSlope">
                      Sheet Metal/Sloped Roof
                    </option>
                  </select>
                  {roofTopType == "SheetMetalSlope" ? (
                    <>
                      <label className="mt-10" htmlFor="tilt">
                        Slope of Roof (in degrees)
                      </label>
                      <input
                        className="w-90"
                        value={tilt}
                        onChange={onChangeUserDetails}
                        type="number"
                        id="name"
                        maxLength={3}
                        name="tilt"
                        placeholder="20"
                      />
                    </>
                  ) : null}
                  <div className="df w-100 jcc alic fdc">
                    <button
                      className="customCta mt-20 w-100"
                      disabled={loading}
                      onClick={onNext}
                    >
                      <span className="mediumFont colorDark">Continue</span>
                      {loading ? (
                        <i
                          className="fa fa-spinner fa-spin colorWhite ml-10"
                          style={{ fontSize: "16px" }}
                        ></i>
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
  // const renderImage = () => (
  //   <img src={require("../../assets/images/polygon.png")} className="polygon" />
  // );

  const renderStep1 = () => (
    <div id={`dynamicId-${0}`} className="w-100 df fdr">
      {initLoader ? (
        <div className="df fdr alic jcc w-100 containerDiv">
          <i
            className="fa fa-spinner fa-spin mt-10 asc df"
            style={{
              fontSize: "30px",
              color: "var(--themeGreen)",
              alignSelf: "center",
            }}
          ></i>
        </div>
      ) : (
        <>{renderMap()}</>
      )}
    </div>
  );

  // const getMiddle = (prop, markers) => {
  //   let values = markers.map(m => m[prop]);
  //   let min = Math.min(...values);
  //   let max = Math.max(...values);
  //   if (prop === 'lng' && (max - min > 180)) {
  //     values = values.map(val => val < max - 180 ? val + 360 : val);
  //     min = Math.min(...values);
  //     max = Math.max(...values);
  //   }
  //   let result = (min + max) / 2;
  //   if (prop === 'lng' && result > 180) {
  //     result -= 360
  //   }
  //   return result;
  // }

  // const findCenter = (markers) => {
  //   return {
  //     lat: getMiddle('lat', markers),
  //     lng: getMiddle('lng', markers)
  //   }
  // }

  // // tests:
  // console.log(findCenter([
  //   { lat: 14.692734621176195, lng: 120.9642877585083 },
  //   { lat: 14.691963317641529, lng: 120.9715473253784 },
  //   { lat: 14.702160611177580, lng: 120.9621292582138 },
  // ]));

  return (
    <div className="">
      <div className="df fdc">{renderStep1()}</div>
      {showModal && !initLoader ? (
        <SelectionModal setShowModal={setShowModal} />
      ) : null}
    </div>
  );
};

export default Onboarding;
