import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import SupplieProfile from "../SupplierProfile";
import LiveBids from "../LiveBids";

const SupplierDashboard = (props) => {
  const supplierData = useSelector((state) => state.supplier.get("data"));
  const supplierStatus = useSelector((state) => state.supplier.get("status"));

  const [tab, setTab] = useState("Profile");

  return (
    <div className="container">
      <div className="cardForm df fdc">
        <div className="tabConatiner bgWhite">
          <div
            onClick={() => setTab("Profile")}
            className={tab == "Profile" ? "activeTab" : "inActiveTab"}
          >
            <span>Profile</span>
          </div>
          <div
            title="Coming Soon..."
            // onClick={() => setTab("Live bids")}
            className={tab == "Live bids" ? "activeTab" : "inActiveTab"}
          >
            <span>Live bids</span>
          </div>
          <div
            title="Coming Soon..."
            // onClick={() => setTab("Project under execution")}
            className={
              tab == "Project under execution" ? "activeTab" : "inActiveTab"
            }
          >
            <span>Project under execution</span>
          </div>
          <div
            title="Coming Soon..."
            // onClick={() => setTab("Completed projects")}
            className={
              tab == "Completed projects" ? "activeTab" : "inActiveTab"
            }
          >
            <span>Completed projects</span>
          </div>
        </div>
        <div className="w-100 mh-75vh">
          {tab == "Profile" ? <SupplieProfile /> : null}
          {/* {tab == "Live bids" ? <LiveBids /> : null} */}
        </div>
      </div>
    </div>
  );
};

export default SupplierDashboard;
